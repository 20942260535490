<template>
  <div class="main-section">
    <div class="wrapper wrapper--xl">
      <div class="main-section-title">
        <h1 class="title">Поиск вакансий</h1>
      </div>
      <SearchForm2 />
    </div>
  </div>
</template>
